.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo0 {
    animation: logo-moving infinite 20s linear;
  }
        .App-logo {
          position: relative;
          /* animation: logo-moving infinite 15s linear; */
        }
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes logo-moving {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }

  25% {
    background-color: yellow;
    left: 200px;
    top: 50px;
  }

  50% {
    background-color: blue;
    left: 0px;
    top: 100px;
  }

  75% {
    background-color: green;
    left: -100px;
    top: 50px;
  }

  100% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
}
header.HomePage {
  width: 80%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.HomePageStrip {
  height: 10vh;
  width: 100%;
  background-color: rgb(80, 176, 176);
}
div.HomePageTitle {
  width: 100%;
  height: 15vh;  color: black;
  font-size: 2em;
  text-align: center;
}
div.HomePageTitle>h1 {
  width: 100%;
}
div.HomePageDescription {
  width: 80%;
  font-size: 1.4em;}
div.HomePage-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
    align-items: center;
}

div.AppHeader {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

div.AppHeader-title {
  font-size: 2.5em;
  font-weight: 400;
  color:black;
}
div.AppHeader-title>a, div.AppHeader-title>a:visited, div.AppHeader-title>a:hover {
  color: black;
  text-decoration: none;
  cursor: default;
}
div.header_menu {
  align-content: end;
  

}
div.header_menu_item {
  float: right;
  min-width: 400px;
}
.lecturer {
  display: flex;
  margin-top: 20px;
}
div.search_div {
  margin: 10px 30px 0 20px;
}
div.register {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.register-title {
  margin: 10px 0;
  color: #61dafb;
  font-size: 1.2rem;
}

span.nowrap {
  white-space: nowrap;
}

.login_form {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}